.a-input {
    $root: &;
    position: relative;

    &-horizontal {
        @include adjust-flex(space-between);
        flex-wrap: wrap;

        #{$root}_subLabel {
            margin-bottom: 0;
            width: 40%;
            padding-right: rem(10);
        }

        #{$root}_wrapper {
            width: 60%;
        }
    }

    &-isReadOnly {
        @include adjust-flex(flex-start, center);
        flex-wrap: wrap;

        #{$root}_subLabel {
            margin-bottom: 0;
            width: unset;
            padding-right: rem(10);
        }

        #{$root}_wrapper {
            flex: 1;
        }

        #{$root}_input {
            padding: 0;
            background-color: transparent;
            border: 0;

            &:disabled {
                background-color: transparent;
            }
        }
    }

    &_label {
        display: flex;
    }

    &_label,
    &_subLabel {
        margin-bottom: rem(8);

        &-required {
            color: $red-orange;
            margin-left: rem(4);
            @include font-base(16, 24);
        }
    }

    &_wrapper {
        position: relative;
    }

    &_wrapInput {
        width: 100%;
        position: relative;
    }

    &_input {
        appearance: none;
        margin: 0;
        outline: none;
        transition: all $transition linear;
        width: 100%;
        color: $gunmetal2;
        background: $white;
        border: rem(1) solid $gainsboro;
        border-radius: rem(4);
        padding: rem(9) rem(16);
        font-weight: 500;
        @include font-base(14, 20);

        &::placeholder {
            color: $manatee;
        }

        &:disabled {
            cursor: not-allowed;
            background: $anti-flash-white1;
        }
    }

    &-password {
        #{$root}_input {
            padding-right: rem(62);
        }
    }

    &-error {
        #{$root}_input {
            border-color: $red-orange !important;

            &::placeholder {
                color: $red-orange;
            }
        }
    }

    &_messageError {
        margin-top: rem(4);
    }

    &_showPassword {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: rem(18);
        border: 0;
        background: transparent;
        display: flex;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    /* stylelint-disable-next-line selector-no-qualifying-type */
    input[type=number] {
        -moz-appearance: textfield;
    }
}
