.m-pulldown {
    $root: &;
    position: relative;

    &-horizontal {
        @include adjust-flex(space-between);
        flex-wrap: wrap;

        #{$root}_label {
            display: block;
            width: 40%;
            padding-right: rem(10);
            margin-bottom: 0;
        }

        #{$root}_wrapHeader {
            width: 60%;
        }

        #{$root}_error {
            margin-left: auto;
        }

        #{$root}_wrapper {
            top: rem(42);
        }
    }

    &_label {
        color: $smoky-black;
        margin-bottom: rem(8);
        font-weight: 500;
        @include font-base(14, 20);
        @include adjust-flex(flex-start, center);

        &-required {
            color: $red-orange;
            margin-left: rem(4);
            @include font-base(14, 20);
        }
    }
  
    &_header {
        background: $white;
        font-weight: 500;
        border: 1px solid $gainsboro;
        border-radius: rem(4);
        color: $manatee;
        cursor: pointer;
        height: rem(40);
        position: relative;
        transition: all $transition linear;
        width: 100%;
        @include adjust-flex(flex-end, stretch);
        @include font-base(14, 20);

        &_content {
            align-items: center;
            display: flex;
            flex-grow: 1;
            height: 100%;
            overflow: hidden;
            padding: rem(10) rem(16);
            text-overflow: ellipsis;
            width: calc(100% - 26px);
  
            span {
                @include text-overflow(1);
                color: $smoky-black;
                @include font-base(14, 20);
                font-weight: 500;
            }
        }

        .m-pulldown_placeholder {
            span {
                color: $manatee;
            }
        }

        &-disabled {
            cursor: not-allowed;

            input {
                background: $anti-flash-white1;
            }
        }
    }

    &_inputSearch {
        width: 100%;
        border: 0;
        outline: none;
        appearance: none;
        padding: rem(10) rem(16);
        color: $smoky-black;
        @include font-base(14, 20);
        font-weight: 500;
        border-radius: rem(4);
    }

    &_arrow {
        flex-shrink: 0;
        margin-right: rem(8);
        position: relative;
        width: rem(16);

        &::after {
            background: url('~assets/icons/ic_chevron.svg') no-repeat;
            background-position: center;
            background-size: rem(16);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: $transition;
            width: 100%;
        }

        &.opened {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    &_wrapper {
        background: $white;
        border-radius: rem(8);
        box-shadow: 0 3px 6px -4px $smoky-black, 0 6px 16px $gainsboro , 0 9px 28px 8px $white;
        margin-top: rem(2);
        overflow: hidden;
        position: absolute;
        width: 100%;
        z-index: 1;
        top: rem(72);
    }

    &_list {
        color: $smoky-black;
        margin: 0;
        max-height: rem(300);
        overflow: auto;
        padding: 0;
        font-weight: 500;
        @include font-base(14, 20);
        @include scroll-bars(4, $la-salle-green, transparent);
    }

    &_item {
        cursor: pointer;
        font-weight: 500;
        list-style: none;
        padding: rem(14) rem(16);
        transition: all $transition linear;
        white-space: normal;

        &:first-child {
            margin-top: rem(4);
        }

        &:last-child {
            margin-bottom: rem(4);
        }

        &:hover {
            background: $gainsboro;
            color: $smoky-black;
        }

        &.none {
            text-align: center;
        }
    }

    &-error {
        #{$root}_header {
            border-color: $red-orange;
        }

        #{$root}_error {
            margin-top: rem(4);
        }

        #{$root}_placeholder {
            span {
                color: $red-orange;
            }
        }

        #{$root}_inputSearch {
            &::placeholder {
                color: $red-orange;
            }
        }
    }
}

.loadingWrap {
    display: flex;
    justify-content: center;
}
