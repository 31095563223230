.a-text-area {
    $root: &;
  
    &_errorMessage {
        color: $red-orange;
        font-weight: 500;
        margin-top: rem(2);
        @include font-base(12, 16);
    }
  
    &_label {
        @include font-base(15, 20);
        color: $eerie-black;
        display: inline-block;
        font-weight: 500;
        margin-bottom: rem(8);

        &-required {
            color: $red-orange;
            margin-left: rem(4);
            @include font-base(16, 24);
        }
    }
  
    &_inputele {
        border: 1px solid $gainsboro;
        border-radius: rem(4);
        box-sizing: border-box;
        font-weight: 500;
        color: $jet;
        margin: 0;
        outline: none;
        padding: rem(10) rem(16);
        resize: none;
        transition: all $transition linear;
        width: 100%;
        font-family: $font-inter !important;
        @include font-base(14, 20);
  
        &::placeholder {
            font-family: $font-inter !important;
            color: $manatee;
        }
  
        &:disabled {
            cursor: not-allowed;
            background-color: $anti-flash-white1;
            border: 1px solid $gainsboro;
        }
    }
  
    &-error {
        #{$root}_inputele {
            border: 1px solid $red-orange;

            &::placeholder {
                color: $red-orange;
            }
        }
    }
    
    &_errorMessage {
        margin-top: rem(4);
    }
}
