.personalInformation {
    &_gender {
        display: flex;
        align-items: center;

        > div {
            &:first-child {
                span {
                    &:last-child {
                        color: $red-orange;
                        margin-left: rem(4);
                        @include font-base(16, 24);
                    }

                    &:first-child:only-child {
                        color: $gunmetal;
                        margin-left: 0;
                        @include font-base(14, 20);
                        margin-right: rem(4);
                    }
                }
            }

            &:last-child {
                @include adjust-flex;

                .a-radio {
                    margin-left: rem(20);
                }
            }
        }
    }
}

.job {
    &_language {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &_row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-7.5);

        > div {
            flex: 0 0 50%;
            padding: 0 rem(7.5);
            max-width: 50%;
        }
    }
}

.skeleton {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border: 1px dashed $mountain-meadow;
    border-radius: rem(4);
    position: relative;

    >div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
    }
}

.images {
    &_face {
        padding: 0 5%;

        &_row {
            @include adjust-flex(space-between);
            margin-top: rem(12);
        }

        &_col {
            flex: 0 0 35%;
            max-width: 35%;
            width: 100%;
        }

        &_note {
            margin-top: rem(12);
        }

        &_wrapper {
            padding: rem(32) rem(16) rem(16);
            background-color: $platinum;

            @include mobile-down {
                padding: rem(16) rem(16);
            }
        }
    }

    &_thumbnail {
        &_row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 rem(-3);
        }

        &_col {
            margin-top: rem(10);
            padding: 0 rem(3);
            flex: 0 0 20%;
            max-width: 20%;
            width: 100%;
        }
    }
}

.notify-text {
    margin-bottom: rem(16);
}
