html,
body {
    font-family: $font-inter;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }

    &-12 {
        margin-top: rem(12);
    }

    &-10 {
        margin-top: rem(10);
    }

    &-8 {
        margin-top: rem(8);
    }
}

.u-mb {
    &-24 {
        margin-bottom: rem(24);
    }
}

.container {
    padding: 0 rem(8);
    max-width: rem(1366);
    margin: rem(20) auto;
}

.divider {
    border-bottom: 1px solid $anti-flash-white1;
    margin: rem(16) 0;
    width: 300vw;
    transform: translateX(-100vw);
}

.cursor-pointer {
    cursor: pointer;
}

.grecaptcha-logo {
    display: none !important;
}

.d-none {
    display: none;
}

.notfound {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include adjust-flex();
}
